import React from 'react'
import loadable from '@loadable/component'

import Link, { Theme as LinkTheme } from 'components/base/Link'

import {
  CtaContent,
  CtaIcon,
  CtaText,
  CtaWrap,
} from './styles'

import { Props } from './types'

const Title = loadable(() => import('../../base/Title').catch(() => () => null))

const CtaColor: React.FC<Props> = ({
  children,
  icon,
  linkTitle,
  linkUrl,
  title,
}): React.ReactElement => (
  <CtaWrap>
    <CtaContent>
      <CtaIcon>{icon}</CtaIcon>
      <Title level={2}>{title}</Title>
      <CtaText>{children}</CtaText>
      <Link theme={LinkTheme.button} to={linkUrl}>
        {linkTitle}
      </Link>
    </CtaContent>
  </CtaWrap>
)

export default CtaColor
