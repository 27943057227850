

import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'

import { StyledInternalLinkThatLooksLikeButton as buttonLink } from 'components/base/Link/styles'

export const CtaWrap = styled.div`
  background: ${COLORS.TAN};
  padding: ${LAYOUT.VERTICALSPACEMOBILE} 20px;
  text-align: center;
  ${MEDIA.medium`
    padding: ${LAYOUT.VERTICALSPACE} 20px;
  `}
`

export const CtaIcon = styled.div`
  margin-bottom: 20px;
  ${MEDIA.medium`
    margin-bottom: 40px;
  `}
`

export const CtaContent = styled.div`
  max-width: ${LAYOUT.WIDTHSHORT};
  margin: auto;
  ${buttonLink} {
    margin-top: 40px;
  }
`

export const CtaText = styled.div`
  font-size: ${FONTS.PARAGRAPH};
  font-weight: ${FONTS.WEIGHTLIGHT};
  ${MEDIA.medium`
    font-size: ${FONTS.PARAGRAPHLARGE};
  `}
`
